<template>
  <base-layout> 
  <header-bar></header-bar>
    <ion-content has-header="true">
      <div v-if="this.user" id="content">
        <div class="profile-img">
          <img  v-if="this.user.picture" id="profile-image" :src="this.user.picture">
          <img v-else id="profile-image" src="@/assets/user.png">
        
        </div>
         <div class="profile-text">
         <h3 id="profile-name">{{this.user.firstName}} {{this.user.lastName}}</h3>
          <span v-if="this.exhibitorName" id="profile-description"> {{this.exhibitorName}}  </span>
           <span v-else id="profile-description"> Mein Account  </span>
         </div>

        <!--button v-if="this.user" class="edit-profile-button" @click="goToAuthServer()">
          Profil bearbeiten
        </button-->

        
        <ion-list v-if="!isDesktop" style="margin: 20px;">
          <!--ion-item v-if="this.user.emailBusiness">
            <div class="content-info-item" >
              <p>{{this.user.emailBusiness}}</p>
              <h2>Email</h2>
            </div>
          </ion-item-->
          <ion-item v-if="this.user.phoneNumber">
            <div class="content-info-item" >
              <p>{{this.user.phoneNumber}}</p>
              <h2>Telefon</h2>
            </div>
          </ion-item>
          <ion-item v-if="this.user.mobileNumber">
            <div class="content-info-item">
              <p>{{this.user.mobileNumber}}</p>
              <h2>Mobile</h2>
            </div>
          </ion-item>
          <ion-item v-if="this.user.faxNumber">
            <div class="content-info-item" >
              <p>{{this.user.faxNumber}}</p>
              <h2>Fax</h2>
            </div>
          </ion-item>
          <ion-item  v-if="this.user.street">
            <div class="content-info-item">
              <p>{{this.user.street}}<span v-if="this.user.city">, {{this.user.city}}</span></p>
              <h2>Adresse</h2>
            </div>
          </ion-item>
          <ion-item  v-if="this.user.country">
            <div class="content-info-item">
              <p>{{this.user.country}}</p>
              <h2>Land</h2>
            </div>
           </ion-item>
        </ion-list>
        
        <div v-if="isDesktop" class="desktop-profile-data">
        <ion-list style="margin-top: 20px;">
          <!--div v-if="this.user.emailBusiness">
            <div class="content-info-item" >
              <p>{{this.user.emailBusiness}}</p>
              <h2>Email</h2>
            </div>
          </div-->
          <div v-if="this.user.phoneNumber || this.user.mobileNumber || this.user.faxNumber">
            <div class="content-info-item" v-if="this.user.phoneNumber" >
              <p>{{this.user.phoneNumber}}</p>
              <h2>Telefon</h2>
            </div>
            <div class="content-info-item" v-if="this.user.mobileNumber">
              <p>{{this.user.mobileNumber}}</p>
              <h2>Mobile</h2>
            </div>
            <div class="content-info-item" v-if="this.user.faxNumber" >
              <p>{{this.user.faxNumber}}</p>
              <h2>Fax</h2>
            </div>
          </div>
          <div v-if="this.user.street || this.user.country" >
            <div class="content-info-item" v-if="this.user.street">
              <p>{{this.user.street}}<span v-if="this.user.city">, {{this.user.city}}</span></p>
              <h2>Adresse</h2>
            </div>
            <div class="content-info-item" v-if="this.user.country">
              <p>{{this.user.country}}</p>
              <h2>Land</h2>
            </div>
           </div>
        </ion-list>
        </div>
        <button type="button" class="btn btn-outline-danger m-4" @click="resetData()">Anwendung zurücksetzen</button>

      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {defineComponent} from 'vue';
import {mapActions} from "vuex";
import { isPlatform } from '@ionic/vue';

import {store} from "@/store/index";

export default defineComponent({
  name: 'User Info',
  data() {
    return {
      
     isDesktop: null,
     user: null,
     exhibitorName: null,
    }
  },

  methods:{
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapActions('sync', ['resetData']),
    ...mapActions('exhibitors', ['getAllExhibitors']),

     async isExhibitor(){
       this.exhibitorsArray =  await this.getAllExhibitors()
      //this.exhibitorsArray= (await axios.get("https://mantel-api.mobile.documedias.systems/v2/conferences/"+CONFERENCE_ID+"/exhibitors")).data //get all exs of conference

      if(this.exhibitorsArray){  //if there is exhibitors
        if(this.user){
        let filteredData = this.exhibitorsArray.filter(ex=>ex.email == this.user.emailBusiness);  //looks for the exhibitor of this consultant
        this.userExhibitor = filteredData[0]//CHANGE THIS AFTER TESTING

        if(this.userExhibitor){
          this.exhibitorName = this.userExhibitor.name;
        }
        if(this.exhibitorName!=null){
          return true;
        } else{
          return false;
        }
        }
      }
   },

    goToAuthServer(){
    window.location.href ="https://auth.documedias.com/user";
  },

  },

 

  async created(){
     this.isDesktop= isPlatform('desktop');
    let userRequest = await store.dispatch("auth/GET_USERDATA");
        this.user = userRequest.data;

    this.isExhibitor();
  }


});
</script>
<style >


      
      
</style>
